.selected-objects-tools-container{
    position: absolute;
    background-color: #f9f9f9;
    resize: both;
    position: absolute;
    top: 480px;
    right: 20px;
    box-shadow: 0 1px 6px rgb(0, 0, 0 / 20%);
    border: 1px solid #ddd;
    width: 300px;
    height: 350px;
    display: flex;
    flex-direction: column;
    overflow: inherit;

    .range-slider__tooltip__label, .range-slider__tooltip__caret{
        visibility: hidden;
    }

    label{
        input{
            margin-right: 10px;
        }
    }

    .selected-objects-tools-header{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #eee;
        border-bottom: 1px solid #d1d1d1;

        .title{
            width: 100%;
            display: flex;
            padding: 3px;
            height: 24px;
            background: #eee;
            color: #333;
            cursor: default;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 22px;
            padding-left: 5px;
            box-sizing: border-box;
            cursor: move;
        }

        svg{
            margin: 5px;
            background: #eee;
            cursor: pointer;
        }
    }

    .selected-objects-tools-footer{
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 3px;
        background: #eee;
        color: #333;
        cursor: default;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 22px;
        padding-left: 5px;
        box-sizing: border-box;
        border-bottom: 1px solid #d1d1d1;

        svg{
            cursor: pointer;
        }
    }

    .selected-objects-tools-content-container{
        width: 100%;
        display: flex;
        padding: 3px;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;

        .css-9gakcf-option{
            background-color: white;
            color: black;
            z-index: 11111;
        }

        .css-26l3qy-menu{
            z-index: 100;
        }

        .color{
            display: flex;
            flex-direction: row;
            .action{
                margin-right: 5px;
                width: 28px;
                height: 28px;
                cursor: pointer;
                justify-content: center;
                display: flex;
                align-items: center;
    
                svg{
                    height: 100%;
                }
    
                .color-container{
                    height: 100%;
                    width: 100%;
                    padding: 5px;
                    .current-color{
                        height: 100%;
                        width: 100%;
                    }
                }
                
            }
        }
        
    }
}
