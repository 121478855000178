.image-editor-container{
    width: 100%;
    height: calc(100% - 85px);
    flex: 1;
    display: flex;
    flex-direction: row;

    .grid-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-width: 1400px;
        position: relative;
        overflow: auto;
        min-height: 800px;

        background:
            linear-gradient(-90deg, rgba(0,0,0,.05) 1px, transparent 1px),
            linear-gradient(rgba(0,0,0,.05) 1px, transparent 1px), 
            linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
            linear-gradient(rgba(0,0,0,.04) 1px, transparent 1px),
            linear-gradient(transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
            linear-gradient(-90deg, #aaa 1px, transparent 1px),
            linear-gradient(-90deg, transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
            linear-gradient(#aaa 1px, transparent 1px),#f2f2f2;
        background-size:
            10px 10px,
            10px 10px,
            80px 80px,
            80px 80px,
            80px 80px,
            80px 80px,
            80px 80px,
            80px 80px;

        .editor-page-layer-container{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            flex-grow: 1;
        }

        .react-transform-wrapper, .transform-component-module_wrapper__1_Fgj {
            width: 100%;
            height: 100%;

            .react-transform-component, .transform-component-module_content__2jYgh  {
                width: 100%;
                height: 100%;
            }
        }
    }
}

#canvas-ruler-h-box-ruler{
    width: calc(100% - 20px) !important;
}

#canvas-ruler-v-box-ruler{
    height: calc(100% - 20px) !important;
}