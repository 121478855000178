.layers-tools-container{
    position: absolute;
    background-color: #f9f9f9;
    resize: both;
    position: absolute;
    top: 110px;
    right: 20px;
    box-shadow: 0 1px 6px rgb(0, 0, 0 / 20%);
    border: 1px solid #ddd;
    width: 300px;
    height: 350px;
    display: flex;
    flex-direction: column;

    .layers-tools-header{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #eee;
        border-bottom: 1px solid #d1d1d1;

        .title{
            width: 100%;
            display: flex;
            padding: 3px;
            height: 24px;
            background: #eee;
            color: #333;
            cursor: default;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 22px;
            padding-left: 5px;
            box-sizing: border-box;
            cursor: move;
        }

        svg{
            margin: 5px;
            background: #eee;
            cursor: pointer;
        }
    }

    .layers-tools-footer{
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 3px;
        background: #eee;
        color: #333;
        cursor: default;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 22px;
        padding-left: 5px;
        box-sizing: border-box;
        border-bottom: 1px solid #d1d1d1;

        svg{
            cursor: pointer;
        }
    }

    .layers-tools-content-container{
        width: 100%;
        display: flex;
        padding: 3px;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
    }
}

.layers-tool-layer-item, .layers-tool-element-group-item{
    display: flex;
    padding: 3px;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 60px;
    
    .title{
        flex-grow: 1;
        margin: 0 5px;
        input{
            width: 100%;
            border: 1px solid #0000FF;
            background-color: transparent;
            outline: none;
        }
    }

    svg{
        cursor: pointer;
    }
    img{
        height: 50px;
        width: 50px;
        background:repeating-conic-gradient(
        #fff 0deg  90deg,
        #d3d3d3 0 180deg) 
        0 0/25% 25%;
    }
}

.selected{
    background-color: #ddd;
}