.login-page-container{
    background-color: #2e303f;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title{
        color: white;
        margin-bottom: 60px;
        font-size: 60px;
        font-weight: bold;
    }

    .panel-wrapper{
        width: 425px;
        margin: 0 auto;
        background-color: #fff;
        margin-bottom: 136px;
        border-color: #7e8197;
        border: 1px solid transparent;
        border-radius: 4px;

        .panel-body{
            padding: 40px;
        }
    }
}