.dropped-icon{
    position: absolute;
    width: 70px;
    height: 70px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        width: 100%;
        height: 100%;
    }
}

.single-resizer{
    z-index: 2222;
}