.editor-page-header{
    width: 350px;
    height: 80px;
    border: 1px black solid;
    margin: 5px;
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(136, 0, 0);
    color: rgb(136, 0, 0);
    background-color: #ddd;
    margin-top: 27px;

    .header-input, .react-datepicker-wrapper{
        margin-left: 5px;
        width: 85%;
        background-color: transparent;
        border: 1px solid;
        outline: none;
        color: #0000FF
    }

    .react-datepicker__input-container{
        input{
            margin-left: 5px;
            width: 85%;
            background-color: transparent;
            border: none;
            outline: none;
            color: #0000FF
        }
    }

    .title{
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        padding: 2px;
        justify-content: space-between;
        border-bottom: 1px solid rgb(136, 0, 0);
    }

    .date{
        display: flex;
        flex-direction: row;
        padding: 2px;
        justify-content: space-between;
        border-top: 1px solid rgb(136, 0, 0);
    }
}