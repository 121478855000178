.layer-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    

    .top-layer{
        position: absolute;
        right: 0;
        top: 0;
        margin: 5px;
    }

    .dnd-drop-container{
        height: 100%;
        width: 100%;
        position: relative;
    }

    .dropped-icon{
        position: absolute;
        width: 70px;
        height: 70px;
        overflow: hidden;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            width: 100%;
            height: 100%;
        }
    }
}