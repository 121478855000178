.single-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    height: 100px;
    border: 1px solid #eee;
    z-index: 10;
}

.single-icon-container:hover{
    background-color: #f5f5f5;
}