.image-layer{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .canvas-image-container{
        border: 1px solid red;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            object-fit:cover;
            position: inherit;
        }
    }

    .canvas-image-container:before {
        content: " ";
        position: absolute;
        z-index: -1;
        top: -7px;
        left: -7px;
        right: -7px;
        bottom: -7px;
        border: 1px solid red;
    }

    .react-transform-wrapper, .transform-component-module_wrapper__1_Fgj {
        width: 100%;
        height: 100%;
    }
}