.sidebar{
    display: flex;
    flex-direction: row;
    border: 1px solid #d1d1d1;

    .nav-tabs{
        background: #eee;
        width: 60px;
        height: 100%;
        float: left;
        white-space: normal;
        vertical-align: top;
        color: #333;
        border-right: 1px solid #d1d1d1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .img-container, .img-container-active{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-top: 15px;
            cursor: pointer;
            border: none;
        }

        .img-container-active{
            background-color: white;
            border-top: 1px solid #d1d1d1;
            border-bottom: 1px solid #d1d1d1;
        }

        .img-container:hover{
            background: #ddd;
        }
    }

    .images-grid{
        width: 250px;
        background: white;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: fit-content(100px);
    }

    .projects-list{
        width: 250px;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        overflow-y: auto;
    }
}

.sidebar-project-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;

    .sidebar-project-item-name{
        margin-left: 5px;
    }
}

.react-contextmenu, .react-contextmenu--visible{
    background-color: #eee;
    padding: 5px;
    z-index: 100;
    border: 1px solid #d1d1d1;
}

.react-contextmenu-item{
    cursor: pointer;
}