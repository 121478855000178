.search-icons-container{
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .filters{
        display: flex;
        flex-direction: column;
        padding: 5px;

        input{
            border: 1px solid #cccccc;
            outline: none;
        }

        .css-9gakcf-option{
            background-color: white;
            color: black;
            z-index: 11111;
        }

        .css-26l3qy-menu{
            z-index: 100;
        }

        .search{
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            button{
                border: none;
                outline: none;
                background-color: #DDDDDD;
                border-radius: 5px;
            }
        }
    }
    .images-grid-filtered{
        width: 250px;
        background: white;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: fit-content(100px);
        overflow-y: auto;
        flex-grow: 1;
    }
    .pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
}

