.draw-area{
  //border: 1px solid black;
  float: left;
  position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.path {
  fill: none;
  stroke-width: 1px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.drawing {
  width: 100%;
  height: 100%;
}

.active{
  stroke: red !important;
}
.polyline {
  fill: none;
  stroke-width: 1px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

circle {
  fill: none;
}

.text{
  border: none;
  outline: none;
}

.focused{
  outline: none;
  border: 1px solid red;
}

rect{
  fill: none;
}

ellipse{
  fill: none;
}

.polyline:hover, line:hover, path:hover, text:hover, circle:hover, rect:hover, ellipse:hover{
  cursor: pointer;
}