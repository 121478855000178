.drawing-tools-container{
    position: absolute;
    background-color: #f9f9f9;
    resize: both;
    position: absolute;
    top: 110px;
    right: 390px;
    box-shadow: 0 1px 6px rgb(0, 0, 0 / 20%);
    border: 1px solid #ddd;
    width: 180px;
    height: 120px;

    strong{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #eee;
        border-bottom: 1px solid #d1d1d1;
        
        .title{
            width: 100%;
            display: flex;
            padding: 3px;
            height: 24px;
            background: #eee;
            color: #333;
            cursor: default;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 22px;
            padding-left: 5px;
            box-sizing: border-box;
            cursor: move;
        }

        svg{
            margin: 5px;
            background: #eee;
            cursor: pointer;
        }
    }

    .icons-container{
        width: 100%;
        display: flex;
        padding: 3px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .action{
            margin-right: 5px;
            width: 28px;
            height: 28px;
            cursor: pointer;
            justify-content: center;
            display: flex;
            align-items: center;

            svg{
                height: 100%;
            }

            .color-container{
                height: 100%;
                width: 100%;
                padding: 5px;
                .current-color{
                    height: 100%;
                    width: 100%;
                }
            }
            
        }

        .color-picker-container{
            position: absolute;
            z-index: 10;
            right: 20%;
            top: 50px;
        }
    }
}