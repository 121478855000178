.toolbar-container{
    white-space: nowrap;
    margin-left: 20px;
    display: flex;
    padding: 2px 0;
    margin-bottom: 10px;
    position: relative;

    .action{
        margin-right: 5px;
        width: 28px;
        height: 28px;
        cursor: pointer;
    }

    .action-divider{
        width: 1px;
        background: #cacaca;
        margin-right: 5px;
    }

    .color-picker-container{
        position: absolute;
        z-index: 10;
        right: 20%;
        top: 50px;
    }
}